import React, { useState } from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";
import { AnimatePresence } from "framer-motion";
import { SolutionOverviewCard } from "@molecules";

const SolutionsOverview = ({ heading, descriptor, solutions }) => {
  const [active, setActive] = useState(solutions.length - 1);
  return (
    <>
      <Container variant="xs" className="flex flex-col gap-5">
        <Text variant="h2" className="text-center">
          {heading}
        </Text>
        {!!descriptor && (
          <Text variant="xl" className="text-center font-body font-medium">
            {descriptor}
          </Text>
        )}
        <div className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {solutions.map((s, i) => (
            <button
              key={s.uid}
              onClick={() => setActive(i)}
              className={classNames(
                "w-full rounded-full border-2 py-3 px-4 font-heading text-sm font-bold transition duration-500",
                {
                  "pointer-events-none border-purple bg-purple text-white":
                    i === active,
                  "border-black hover:bg-black hover:text-white": i !== active,
                }
              )}
              type="button"
            >
              {s.heading}
            </button>
          ))}
        </div>
      </Container>
      <Container className="mt-20">
        <AnimatePresence mode="wait">
          <SolutionOverviewCard
            key={solutions[active].uid}
            {...solutions[active]}
          />
        </AnimatePresence>
      </Container>
    </>
  );
};

export default SolutionsOverview;
